export const MANAGERS_INFO = {
  EASTERN: [
    {
      name: "Nicholas D'Alessandro",
      email: 'nicholas.dalessandro@toyota.com',
      area: 'Eastern Area'
    }
  ],
  WESTERN: [
    {
      name: 'Lisa Toyosaki',
      email: 'lisa_toyosaki@lexus.com',
      area: 'Western Area'
    },
    {
      name: 'David Dawood',
      email: 'david.dawood@toyota.com',
      area: 'Western Area'
    }
  ],
  CENTRAL: [
    {
      name: 'Chris Tenebehn',
      email: 'chris.tenebehn@toyota.com',
      area: 'Western Area'
    },
    {
      name: 'Marie Macaulay',
      email: 'marie.macaulay@toyota.com',
      area: 'Western Area'
    }
  ],
  SOUTHERN: [
    {
      name: 'Christopher Reid',
      email: 'christopher.reid@lexus.com',
      area: 'Southern Area'
    },
    {
      name: 'Jacky Ito',
      email: 'jacky.ito@lexus.com',
      area: 'Southern Area'
    }
  ]
}
